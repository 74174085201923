import React from 'react';


import Main from './Main';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
 
} from "react-router-dom";






function App() {
  return (
    <div className="App">

      <Main />
    </div>
  );
}

export default Main;
